import React, { useState, useEffect } from 'react';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  faShareAlt,
  faEdit,
  faPrint,
  faStar,
  faUndo,
  faWindowClose,
  faArrowsDownToPeople,
  faCopy,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';
import ShareSale from 'client/components/ShareSale/ShareSale.js';
import SatisfactionSurvey from '../../components/satisfaction-survey';
import DevolutionModal from './DevolutionModal.js';
import CustomButton from 'client/components/CustomButton/CustomButton.jsx';
import api from 'services/api';
import config from 'config';
import { toastr } from 'react-redux-toastr';
import { usePlanSignatureContext } from '../../../contexts/plan-signature';
import UnavailableFeatureModal from '../../components/UnavailableFeatureModal';
import { cancelSale } from './NewSale/FormSale/redux/actions';
import AlertModal from 'components/AlertModal/AlertModal';
import { useAuth } from 'contexts/auth';

import lossReasonsRepository from 'repositories/LossReasons';
import AppointmentsModal from './AppointmentsModal';
import { cashierBankHistoricValidations } from 'client/components/BlockSalesModals/actions';
import BlockSalesModals from 'client/components/BlockSalesModals';
import DuplicateSalesModal from './DuplicateSalesModal';

const SalesTableActions = ({
  sale,
  loadSales,
  history,
  setSelectedSaleId,
  selectedSaleId,
}) => {
  const [shareModal, setShareModal] = useState(false);
  const [surveyModal, setSurveyModal] = useState(false);
  const [devolutionModal, setDevolutionModal] = useState(false);
  const [surveyData, setSurveyData] = useState({});
  const [isOpenFeatureNotAvailableModal, setIsOpenFeatureNotAvailableModal] =
    useState(false);
  const [hasProducts, setHasProducts] = useState(false);
  const [hasReturnedItems, setHasReturnedItems] = useState(false);
  const [cancelSaleModal, setCancelSaleModal] = useState(false);

  const { company } = useAuth();

  const [
    isAddLossReasonConfirmationModalOpen,
    setIsAddLossReasonConfirmationModalOpen,
  ] = useState(false);

  const [isSelectLossReasonModalOpen, setIsSelectLossReasonModalOpen] =
    useState(false);

  const [lossReasonsOptions, setLossReasonsOptions] = useState([]);

  const [loadingLossReasons, setLoadingLossReasons] = useState(false);

  const [isAppointmentsModalOpen, setIsAppointmentsModalOpen] = useState(false);
  const [isDuplicateSalesModalOpen, setIsDuplicateSalesModalOpen] =
    useState(false);

  const [selectedLossReason, setSelectedLossReason] = useState('');

  const { isPlanFree, isPlanStart, isPlanBasic } = usePlanSignatureContext();

  const { saleReducer } = useSelector((state) => state);
  const { loadingSpin } = saleReducer;

  const { companyId, userId } = useAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    checkSaleItemsType(sale);
    checkReturnedItems(sale);
  }, []);

  const handlePrint = () => {
    const link = getSaleHashLink();
    window.open(link, '_blank');
  };

  const getSaleHashLink = () => {
    const hash = `Vend=v723dha5tHSSpS83711${sale.id}5422hsGsdAQLk8423oPL31`;
    return `${window.location.origin}/sheets/${hash}`;
  };

  const checkSaleItemsType = async (sale) => {
    const saleTypes = sale.SalesItems.map((saleItem) => saleItem.Type);

    setHasProducts(saleTypes.includes('Produto'));
  };

  const checkReturnedItems = async (sale) => {
    const hasReturnedItems = sale.SalesItems.some(
      (saleItem) => !!saleItem.QuantityItemsReturned
    );

    setHasReturnedItems(hasReturnedItems);
  };

  const getSurvey = async () => {
    if (!!sale.SatisfactionSurveyId) {
      try {
        const res = await api.get(
          config.queridocarroendpoint +
            `satisfaction-survey/?salesCode=${sale.id}`
        );
        setSurveyData({
          ...res.data[0],
          SatisfactionSurveyId: sale.SatisfactionSurveyId,
        });
      } catch (err) {
        console.log(err);
        return toastr.warning(
          'Ocorreu um erro ao carregar a avaliação',
          'Por favor, tente novamente'
        );
      }
    }

    setSurveyModal(true);
  };

  const onHideSurveyModal = () => {
    setSurveyModal(false);
    setSurveyData(null);
  };

  const handleOpenDevolutionModal = () => {
    if (isPlanFree || isPlanStart || isPlanBasic)
      return setIsOpenFeatureNotAvailableModal(true);

    if (sale.Status === 'Cancelado(a)' && !hasReturnedItems) {
      return toastr.warning(
        `A ${sale.SalesType.Description.toLowerCase()} não possui uma devolução`
      );
    }

    setDevolutionModal(true);
  };

  const renderCancelMessage = () => {
    let message;

    if (!sale.id)
      message =
        'Essa ação não salvará nenhuma das informações apresentadas nessa tela. Deseja realmente cancelar ?';
    else {
      switch (sale.SalesType.Description) {
        case 'Venda':
          message = 'Deseja realmente cancelar a Venda?';
          break;

        case 'Ordem de Serviço':
          message = 'Deseja realmente cancelar a Ordem de Serviço?';
          break;

        case 'PDV':
          message = 'Deseja realmente cancelar o PDV?';
          break;

        default:
          message = 'Deseja realmente cancelar o Orçamento?';
          break;
      }
    }

    return message;
  };

  async function handleGetLossReasons() {
    try {
      setLoadingLossReasons(true);
      const lossReasons = await lossReasonsRepository.getAllByCompany({
        companyId,
        isActive: '1',
      });

      return lossReasons;
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar os motivos de perda. Tente novamente!'
      );
    } finally {
      setLoadingLossReasons(false);
    }
  }

  async function handleCancelClick() {
    setCancelSaleModal(true);
  }

  async function handleCancelSaleConfirmation() {
    if (
      !isPlanStart &&
      !isPlanFree &&
      sale.SalesType.Description === 'Orçamento'
    ) {
      const lossReasons = await handleGetLossReasons();
      if (lossReasons.data.length > 0) {
        setLossReasonsOptions(lossReasons.data);
        setCancelSaleModal(false);
        setIsAddLossReasonConfirmationModalOpen(true);
      } else {
        handleCancelSale();
      }
    } else {
      handleCancelSale();
    }
  }

  async function handleCancelSale() {
    if (!sale.id) {
      history.goBack();
    } else {
      dispatch(
        cancelSale(
          sale.id,
          companyId,
          sale.SalesType.Description,
          sale,
          userId,
          loadSales,
          selectedLossReason
        )
      );
    }
  }

  function handleOpenAppointmentsModal({ saleStatus, saleType }) {
    switch (saleStatus) {
      case 'Cancelado(a)':
        return toastr.warning(
          'Função indisponível',
          'A função de apontamento de serviço é disponível apenas para ordens de serviço abertas e finalizadas'
        );

      default:
        if (isPlanFree || isPlanStart) {
          return setIsOpenFeatureNotAvailableModal(true);
        }

        setIsAppointmentsModalOpen(true);
    }
  }

  async function handleEditSale() {
    setSelectedSaleId(sale.id);
    if (company?.hasCashierControl) {
      const isValid = await dispatch(cashierBankHistoricValidations(companyId));
      if (isValid.payload) {
        return handleRedirectToSale(true);
      }
    } else {
      return handleRedirectToSale(true);
    }
  }

  function handleRedirectToSale(flagged) {
    if (flagged || selectedSaleId === sale.id) {
      return history.push(`sale#${sale.id}`, { saleId: sale.id });
    }
  }

  function handleOpenDuplicateSales({ saleStatus, saleType }) {
    setIsDuplicateSalesModalOpen(true);
  }

  return (
    <>
      <div id="sales-table-actions-wrapper">
        <div>
          <span onClick={handleEditSale}>
            <FontAwesomeIcon
              title="Editar"
              color="black"
              icon={faEdit}
              style={{
                height: '1.6em',
                width: '1.6em',
                marginBottom: -1,
              }}
            />
          </span>
        </div>

        <div>
          <FontAwesomeIcon
            onClick={() =>
              handleOpenAppointmentsModal({
                saleStatus: sale.Status,
                saleType: sale.SalesType_id,
              })
            }
            title="Lançamento de Apontamento"
            color={sale.Appointments?.length > 0 ? '#72bb53' : 'black'}
            icon={faArrowsDownToPeople}
            style={{
              height: '1.6em',
              width: '1.6em',
              marginBottom: -1,
            }}
          />
        </div>

        <div>
          <FontAwesomeIcon
            onClick={() => {
              setShareModal(true);
            }}
            title="Compartilhar"
            cursor="pointer"
            color="black"
            icon={faShareAlt}
          />
        </div>

        <div>
          <FontAwesomeIcon
            onClick={handlePrint}
            title="Visualizar"
            icon={faPrint}
            aria-hidden="false"
          />
        </div>

        <div>
          <FontAwesomeIcon
            onClick={getSurvey}
            title="Avaliação"
            style={{
              display:
                sale.Type === 'Orçamento' ||
                sale.Status === 'Cancelado(a)' ||
                sale.Status === 'Financiamento em Análise' ||
                sale.Status === 'Financiamento Aprovado' ||
                sale.Status === 'Financiamento Rejeitado' ||
                sale.Status === 'Aguardando 1º Pagamento' ||
                sale.Status === 'Financiamento Expirado' ||
                sale.Status === 'Cancelado(a)' ||
                sale.Status === 'Em Aberto(a)'
                  ? 'none'
                  : '',
              color: !!sale.SatisfactionSurveyId ? '#ffb400' : 'gray',
            }}
            icon={faStar}
            aria-hidden="false"
          />
        </div>

        {hasProducts &&
          sale.Status !== 'Em Aberto(a)' &&
          sale.Status !== 'Financiamento em Análise' &&
          sale.Status !== 'Financiamento Aprovado' &&
          sale.Status !== 'Aguardando 1º Pagamento' &&
          sale.Status !== 'Financiamento Expirado' &&
          sale.Status !== 'Financiamento Rejeitado' && (
            <div>
              <FontAwesomeIcon
                onClick={handleOpenDevolutionModal}
                title="Devolução"
                color="black"
                icon={faUndo}
                aria-hidden="false"
                style={sale.Status === 'Cancelado(a)' && { marginRight: -5 }}
              />
            </div>
          )}
        <div>
          {sale.Status !== 'Cancelado(a)' && (
            <FontAwesomeIcon
              onClick={handleCancelClick}
              title={
                sale.SalesType.Description === 'Ordem de Serviço'
                  ? 'Cancelar Ordem de Serviço'
                  : sale.SalesType.Description === 'Orçamento'
                  ? 'Cancelar Orçamento'
                  : 'Cancelar Venda'
              }
              icon={faWindowClose}
              aria-hidden="false"
              color="red"
            />
          )}
        </div>
        <div>
          {sale.Status !== 'Cancelado(a)' && (
            <FontAwesomeIcon
              onClick={handleOpenDuplicateSales}
              title="Copiar Venda/OS"
              cursor="pointer"
              style={{
                height: '1.6em',
                width: '1.6em',
                marginLeft: '3px',
                marginRight: '3px',
              }}
              icon={faCopy}
            />
          )}
        </div>
      </div>
      {shareModal && (
        <ShareSale
          show={shareModal}
          animation={true}
          onHide={() => setShareModal(false)}
          sale_id={sale.id}
          sale_Type={sale.Type}
          customer_id={sale.Customer?.id}
          onSubmit={() => setShareModal(false)}
        />
      )}
      {/* MODAL DE DEVOLUÇÃO DE PRODUTOS */}
      {devolutionModal && (
        <DevolutionModal
          animation={true}
          sale={sale}
          totalItems={sale.SalesItems.length}
          show={devolutionModal}
          onHide={() => setDevolutionModal(false)}
          loadSales={() => loadSales()}
          initialValues={{ products: [] }}
        />
      )}
      <Modal show={surveyModal} onHide={onHideSurveyModal} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="survey-modal-header">
              <h4>
                <strong>{sale.Company?.Trading_Name}</strong>
              </h4>
              <h6>
                <strong>Veículo: </strong>
                {sale.Vehicle?.Model}
              </h6>
              <h6>
                <strong>Placa: </strong>
                {sale.Vehicle?.License_Plate}
              </h6>

              {!sale.SatisfactionSurveyId && (
                <>
                  <div>
                    <h6>O cliente ainda não avaliou a {sale.Type}</h6>
                  </div>
                </>
              )}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="survey-modal-body">
            <SatisfactionSurvey {...surveyData} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            bsStyle="danger"
            style={{ width: 'fit-content' }}
            onClick={onHideSurveyModal}
            fill
          >
            Fechar
          </CustomButton>
        </Modal.Footer>
      </Modal>
      <UnavailableFeatureModal
        show={isOpenFeatureNotAvailableModal}
        onHide={() => setIsOpenFeatureNotAvailableModal(false)}
      />
      {isAppointmentsModalOpen && (
        <AppointmentsModal
          open={isAppointmentsModalOpen}
          sale={sale}
          handleClose={() => setIsAppointmentsModalOpen(false)}
        />
      )}{' '}
      {isDuplicateSalesModalOpen && (
        <DuplicateSalesModal
          open={isDuplicateSalesModalOpen}
          sale={sale}
          onCancel={() => setIsDuplicateSalesModalOpen(false)}
          onSubmit={() => setIsDuplicateSalesModalOpen(false)}
        />
      )}{' '}
      <AlertModal
        show={cancelSaleModal}
        loading={loadingSpin || loadingLossReasons}
        message={renderCancelMessage()}
        onHide={() => setCancelSaleModal(false)}
        onCancel={() => setCancelSaleModal(false)}
        onSubmit={() => handleCancelSaleConfirmation()}
      />
      <AlertModal
        show={isAddLossReasonConfirmationModalOpen}
        loading={loadingSpin}
        hideCloseButton
        message={
          <>
            <strong>
              Você deseja selecionar um motivo de perda para categorizar o
              orçamento cancelado?
            </strong>
            <br />
            <br />
            Ao selecionar o motivo de perda, você poderá gerar o relatório de
            Listagem de Orçamentos cancelados para visualizar os motivos e os
            detalhes dos orçamentos.
          </>
        }
        onCancel={() => {
          handleCancelSale();
        }}
        onSubmit={() => {
          setIsAddLossReasonConfirmationModalOpen(false);
          setIsSelectLossReasonModalOpen(true);
        }}
      />
      <AlertModal
        show={isSelectLossReasonModalOpen}
        loading={loadingSpin}
        hideCloseButton
        message={
          <>
            <strong>Selecione o motivo de perda:</strong>
            <br />
            <br />
            <select
              className="form-control"
              value={selectedLossReason}
              onChange={(e) => setSelectedLossReason(e.target.value)}
            >
              <option value="" disabled>
                Selecione
              </option>
              {lossReasonsOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.description}
                </option>
              ))}
            </select>
          </>
        }
        onCancelLabel="Voltar"
        onSubmitLabel="Salvar"
        onCancel={() => {
          setIsSelectLossReasonModalOpen(false);
          setSelectedLossReason('');
          setIsAddLossReasonConfirmationModalOpen(true);
        }}
        onSubmit={() => {
          if (!selectedLossReason) {
            return toastr.warning('Selecione um motivo e tente novamente');
          }
          handleCancelSale();
        }}
      />
      {selectedSaleId === sale.id && (
        <BlockSalesModals
          onSubmit={() => handleEditSale()}
          onCancel={() => setSelectedSaleId(null)}
        />
      )}
    </>
  );
};

export default withRouter(SalesTableActions);
