import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import PercentInput from 'client/components/Percent';
import api from '../../../../services/api';
import config from '../../../../config';
import brandsRepository from 'repositories/Brands';
import productsRepository from 'repositories/Products';
import ModalAssociateProduct from '../components/ModalAssociateProduct';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';

import {
  currency,
  formatQuantityWithThousands,
  formatThousandWithPercentage,
} from '../../ToNormalize/ToNormalize';
import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';
import { InputContainer } from 'v2/components/Input';
import { InputSelectInTable } from 'v2/components/Input/InputSelect';
import { InputCurrency } from 'v2/components/Input';
import { usePurchaseXmlImport } from '../hook/PurchaseXmlImportHook';

function Products({ nextPage, previousPage, onSubmit }) {
  const { companyId, company } = useAuth();
  const isWholesale =
    company?.companyConfig.sellingPriceType === 'Preço Atacado e Varejo';
  const dispatch = useDispatch();
  const [itemToEdit, setItemToEdit] = useState({});
  const [query, setQuery] = useState('');
  const [modalAssociateProduct, setModalAssociateProduct] = useState(false);
  const [marginForAll, setMarginForAll] = useState(0);
  const [isCreateProductLoading, setIsCreateProductLoading] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brandId, setBrandId] = useState([
    {
      value: '*',
      label: 'Selecione',
    },
  ]);

  const {
    items = [],
    providerInDB,
    loading,
  } = useSelector((state) => state.form.PurchaseXmlImport.values);

  const { values } = useSelector((state) => state.form.PurchaseXmlImport);

  const { handleFinish } = usePurchaseXmlImport(
    onSubmit,
    companyId,
    providerInDB
  );

  const onFinish = () => {
    handleFinish(values, items);
  };

  const handleLoadProducts = async (productId) => {
    try {
      const response = await productsRepository.getById(productId);

      return response;
    } catch (err) {
      console.log(err);
    }
  };

  const handleLoadMarginAndSalePrice = async () => {
    const updatedItems = await Promise.all(
      items.map(async (item) => {
        if (item.productId) {
          const product = await handleLoadProducts(item.productId);
          if (product) {
            return {
              ...item,
              margin: product.Profit_Margin,
              newMargin: product.Profit_Margin,
              salesPrice: product.Sale_Price,
              profitMarginWholesale: product.profitMarginWholesale
                ? product.profitMarginWholesale
                : product.Profit_Margin,
              salePriceWholesale: product.salePriceWholesale
                ? product.salePriceWholesale
                : product.Sale_Price,

              newSalesPrice:
                product.Purchase_Price +
                (product.Profit_Margin / 100) * product.Purchase_Price,
            };
          }
        }
        return item;
      })
    );

    dispatch(change('PurchaseXmlImport', 'items', updatedItems));

    return updatedItems;
  };

  const handleCreateItem = async (item) => {
    setIsCreateProductLoading(true);
    const product = handleFormatObjectToCreate(item);
    try {
      await handleCreateProducts([product]);
      setIsCreateProductLoading(false);
    } catch (err) {
      setIsCreateProductLoading(false);
      console.log(err);
    }
  };

  const handleCreateItems = async () => {
    const filteredItems = items.filter((p) => !p.productId);
    if (!filteredItems.length)
      return toastr.warning('Todos os Produtos já foram cadastrados');

    const products = filteredItems.map(handleFormatObjectToCreate);
    await handleCreateProducts(products);
  };

  const handleOpenAssociateProduct = (item) => {
    setItemToEdit(item);
    setModalAssociateProduct(true);
  };

  const handleCloseAssociateProduct = () => {
    dispatch(change('PurchaseXmlImport', 'itemToAssociate', {}));
    setItemToEdit({});
    setModalAssociateProduct(false);
  };

  const handleCreateProducts = async (products) => {
    await dispatch([
      change('PurchaseXmlImport', 'loading', true),
      change(
        'PurchaseXmlImport',
        'loadingMessage',
        `Aguarde, cadastrando produto${products.length > 1 ? 's' : ''}`
      ),
    ]);

    try {
      const { data } = await api.patch(config.endpoint + `products`, {
        Company_id: companyId,
        providerId: providerInDB.id,
        products,
      });

      const filterdItems = [...items];

      for (let p of data) {
        filterdItems.map((product) => {
          if (
            product.description.toUpperCase() === p.Description &&
            p.Manufacturer_Ref === product.providerCode
          ) {
            product.productCode = p.Code;
            product.productId = p.id;
            product.salesPrice = product.newSalesPrice;
            product.margin = product.newMargin;
            product.salePriceWholesale = product.salePriceWholesale;
            product.profitMarginWholesale = product.profitMarginWholesale;
          }
        });
      }

      await dispatch(change('PurchaseXmlImport', 'items', filterdItems));

      toastr.success(
        `Produto${products.length > 1 ? 's' : ''} cadastrado${
          products.length > 1 ? 's' : ''
        } com sucesso`
      );
    } catch (err) {
      console.log(err);
      toastr.error(
        'Ocorreu algum erro inesperado.',
        'Por favor, tente novamente mais tarde.'
      );
    }

    dispatch(change('PurchaseXmlImport', 'loading', false));
  };

  const handleFormatObjectToCreate = (item) => {
    return {
      Description: item.description.toUpperCase(),
      Sale_Price: item.newSalesPrice,
      salePriceWholesale: item.salePriceWholesale,
      Purchase_Price: item.unitValue,
      IsActive: true,
      Unit_Type: item.unitType,
      Profit_Margin: item.newMargin,
      profitMarginWholesale: item.profitMarginWholesale,
      Bar_Code: item.cEAN,
      Tribute_BarCode: item.cEANTrib,
      Type: 'Produto',
      CFOP_InState_Code: item.CFOP_InState_Code,
      CFOP_OutState_Code: item.CFOP_OutState_Code,
      CSOSN: item.CSOSN,
      CST: item.CST,
      NCM_Code: item.ncm,
      Origin: item.origin,
      Manufacturer_Ref: item.providerCode,
      Stock_Quantity: 0,
      PIS_Exit: item.CSTPIS,
      COFINS_Exit: item.CSTCOFINS,
      productProviderCode: item.providerCode,
      surname: item.surname,
      Multiplier_Base: item.multiplierBase,
      ANP: item.CodANP,
      ANP_Description: item.DescANP,
      ANP_UF_Code: item.UFANP,
      Brand_id: item.brand && item.brand.value,
      CEST_Code: item.cest,
    };
  };

  const handleApplyMarginForAll = async () => {
    if (!isChecked.length) {
      toastr.warning(
        'Nenhum produto selecionado',
        'Selecione ao menos 1 produto para aplicar a nova margem'
      );
      return;
    }
    const margin = parseFloat(marginForAll);

    const modifiedItems = items.map((p) => {
      if (
        isChecked.some((checkedItem) => checkedItem.productId === p.productId)
      ) {
        const newSalesPrice = calculateSalesPrice(p.unitValue, margin);
        const salePriceWholesale = calculateSalesPriceWholesale(
          p.unitValue,
          margin
        );
        return {
          ...p,
          newMargin: margin,
          newSalesPrice,
          salePriceWholesale,
          profitMarginWholesale: margin,
        };
      }
      return p;
    });

    dispatch(change('PurchaseXmlImport', 'items', modifiedItems));
  };

  const handleFilters = (product) => {
    return handleFilterByQuery(product);
  };

  const handleCheckedItems = async (item) => {
    const isItemChecked = await isChecked.some(
      (checkedItem) => checkedItem.productId === item.productId
    );

    if (isItemChecked) {
      setIsChecked(
        await isChecked.filter(
          (checkedItem) => checkedItem.productId !== item.productId
        )
      );
    } else {
      setIsChecked((prevItems) => {
        return [...prevItems, item];
      });
    }
  };

  const handleNextStep = async () => {
    if (items.some((p) => !p.productId)) {
      toastr.warning('Vincule ou cadastre todos os itens para avançar');
    } else {
      nextPage();
    }
  };

  const calculateSalesPrice = (unitValue, newMargin) => {
    let margin = (unitValue * newMargin) / 100;
    let value = parseFloat(unitValue) + parseFloat(margin);

    return value;
  };
  const calculateSalesPriceWholesale = (unitValue, profitMarginWholesale) => {
    let marginWholesale = (unitValue * profitMarginWholesale) / 100;
    let value = parseFloat(unitValue) + parseFloat(marginWholesale);

    return value;
  };

  const calculateMargin = (unitValue, salesPrice) => {
    return ((salesPrice - unitValue) / unitValue) * 100;
  };

  const calculateMarginWholesale = (unitValue, salePriceWholesale) => {
    return ((salePriceWholesale - unitValue) / unitValue) * 100;
  };

  const handleChangeValueMargin = (index, props, newMargin) => {
    const marginPercentage = parseFloat(
      newMargin.replace('%', '').replace(',', '.')
    );

    const newSalesPriceCalc = calculateSalesPrice(
      props.unitValue,
      marginPercentage
    );

    const newSalesPrice = isNaN(newSalesPriceCalc) ? 0 : newSalesPriceCalc;

    dispatch(
      change('PurchaseXmlImport', `items[${index}].newMargin`, marginPercentage)
    );
    dispatch(
      change(
        'PurchaseXmlImport',
        `items[${index}].newSalesPrice`,
        newSalesPrice
      )
    );
  };
  const handleChangeValueMarginWholesale = (
    index,
    props,
    profitMarginWholesale
  ) => {
    const marginWholesalePercentage = parseFloat(
      profitMarginWholesale.replace('%', '').replace(',', '.')
    );

    const newSalesPriceCalc = calculateSalesPriceWholesale(
      props.unitValue,
      marginWholesalePercentage
    );

    const newSalesPrice = isNaN(newSalesPriceCalc) ? 0 : newSalesPriceCalc;

    dispatch(
      change(
        'PurchaseXmlImport',
        `items[${index}].profitMarginWholesale`,
        marginWholesalePercentage
      )
    );
    dispatch(
      change(
        'PurchaseXmlImport',
        `items[${index}].salePriceWholesale`,
        newSalesPrice
      )
    );
  };

  const handleChangeValueSalesPrice = (index, props, newSalesPrice) => {
    const newMargin = calculateMargin(props.unitValue, newSalesPrice);

    dispatch(
      change(
        'PurchaseXmlImport',
        `items[${index}].newSalesPrice`,
        newSalesPrice
      )
    );
    dispatch(
      change('PurchaseXmlImport', `items[${index}].newMargin`, newMargin)
    );

    setTimeout(() => {
      document.getElementById(`newSalesPrice${index}`).focus();
    }, 50);
  };
  const handleChangeValueSalesPriceWholesale = (
    index,
    props,
    salePriceWholesale
  ) => {
    const newMarginWholesale = calculateMarginWholesale(
      props.unitValue,
      salePriceWholesale
    );

    dispatch(
      change(
        'PurchaseXmlImport',
        `items[${index}].salePriceWholesale`,
        salePriceWholesale
      )
    );
    dispatch(
      change(
        'PurchaseXmlImport',
        `items[${index}].profitMarginWholesale`,
        newMarginWholesale
      )
    );

    setTimeout(() => {
      document.getElementById(`salePriceWholesale${index}`).focus();
    }, 50);
  };

  const handleFilterByQuery = (product) => {
    return (
      !query ||
      product.description.toLowerCase().includes(query.toLowerCase()) ||
      product.productCode?.toString()?.includes(query) ||
      product.providerCode?.toString()?.includes(query) ||
      product.cEAN?.toString()?.includes(query)
    );
  };

  function handleChangeBrand(value, index) {
    setBrandId(value);

    dispatch(change('PurchaseXmlImport', `items[${index}].brand`, value));
  }

  const handleMultiplierBaseChange = (e, index, productId) => {
    const value = parseInt(e.target.value, 10);

    if (value === 0) {
      e.target.value = 1;
      toastr.warning(
        'Valor inválido',
        '0 não é um valor válido de estoque. Insira um valor correto.'
      );
    } else if (value > 999) {
      toastr.warning(
        'Valor inválido',
        'O valor deve ser um número inteiro entre 1 e 999. Insira um valor correto.'
      );
    } else {
      dispatch(
        change('PurchaseXmlImport', `items[${index}].multiplierBase`, value)
      );
    }

    setTimeout(() => {
      document.getElementById(`baseMulti${index}`).focus();
    }, 50);
  };

  const loadBrands = async () => {
    try {
      const brands = await brandsRepository.getAllByCompany(companyId);
      const serializedBrands = brands.map(
        (brand) => ({
          label: brand.Description,
          value: brand.id,
        }),
        [brands]
      );

      serializedBrands.unshift({ value: '*', label: 'Selecione' });
      setBrands(serializedBrands);
      setBrandId(serializedBrands);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as marcas. Por favor, tente novamente'
      );
    }
  };

  useEffect(() => {
    loadBrands();
    handleLoadMarginAndSalePrice();
  }, []);

  return (
    <>
      <p id="span-products-with-icms">
        Caso sua entrada tenha ICMS ST e não tenha no produto, valide o campo
        Calcula ST no cadastro do produto e faça novamente a entrada.
      </p>

      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
        }}
      >
        <span
          style={{ fontSize: '12px', color: '#d9534f', paddingRight: '2.5%' }}
        >
          *Campos Obrigatórios
        </span>
      </div>
      <div className="xml-import-prducts-container">
        <div
          className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
          style={{
            marginTop: '5px',
            paddingLeft: '10px',
            paddingRight: '10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            className="col-xs-12 col-sm-5 col-md-5 col-lg-5"
            style={{ marginLeft: '0px', paddingLeft: '0px' }}
          >
            <i
              id="iconepesquisar"
              style={{ cursor: 'pointer' }}
              className="fa fa-search"
            />
            <input
              type="text"
              id={`query`}
              className="form-control new-foco-input"
              style={{
                width: '435px',
                paddingLeft: '35px',
                fontSize: '12px',
                height: '35px',
              }}
              placeholder="Pesquisa por Código / Descrição / Ref. Fab / Código de Barras"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <div id="import-xml-input-default-margin-products">
            <label>Margem Padrão:</label>
            <PercentInput
              className="form-control new-foco-input"
              onChangeEvent={(e) => setMarginForAll(e.target.value)}
              value={marginForAll}
            />
            <button
              type="button"
              className="xml-import-button-success button-h35"
              disabled={!isChecked.length && marginForAll > 0}
              onClick={handleApplyMarginForAll}
              style={{ fontSize: '12px' }}
            >
              Aplicar Margem
            </button>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: '1%',
          }}
        >
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              const filteredItems = items
                .filter(handleFilters)
                .filter((item) => item.productId);
              if (isChecked.length === filteredItems.length) {
                setIsChecked([]);
              } else {
                setIsChecked(filteredItems);
              }
            }}
          >
            <span style={{ fontSize: '11px' }}>
              {isChecked.length ===
              items.filter(handleFilters).filter((item) => item.productId)
                .length
                ? 'Remover todos'
                : 'Selecionar todos'}{' '}
              (
              {
                items.filter(handleFilters).filter((item) => item.productId)
                  .length
              }
              )
            </span>
          </a>
        </div>
        <div
          style={{
            paddingRight: '10px',
            paddingLeft: '10px',
            height: '300px',
            overflowY: 'scroll',
            overflowX: 'auto',
          }}
        >
          <ReactTable
            style={{
              width: '2050px',
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: '12px',
              overflow: 'none',
            }}
            getTableProps={() => ({
              style: {
                overflow: 'hidden',
              },
            })}
            getTbodyProps={() => ({
              style: {
                overflow: 'hidden',
              },
            })}
            data={items.filter(handleFilters)}
            columns={[
              {
                Header: '',
                accessor: 'id',
                width: 50,
                Cell: (props) =>
                  !props.original.productId ? (
                    <FontAwesomeIcon
                      title="Cadastrar Produto"
                      cursor="pointer"
                      style={{
                        height: '15px',
                        width: '15px',
                        color: 'green',
                      }}
                      icon={faPlusCircle}
                      onClick={() => {
                        if (isCreateProductLoading === false) {
                          handleCreateItem(props.original);
                        }
                      }}
                    />
                  ) : (
                    <div className="flex center flex.align-center">
                      <FontAwesomeIcon
                        title="Selecionar"
                        cursor="pointer"
                        icon={
                          isChecked.some(
                            (checkedItem) =>
                              checkedItem.productId === props.original.productId
                          )
                            ? faCheckSquare
                            : faSquare
                        }
                        style={{ width: '14px', height: '14px' }}
                        onClick={() => handleCheckedItems(props.original)}
                      />
                    </div>
                  ),
              },
              {
                Header: 'Código',
                accessor: 'productCode',
                headerClassName: 'text-center',
                width: 120,
                className: 'texto',
                Cell: (props) =>
                  props.value ?? (
                    <button
                      className="btn-link"
                      onClick={() => handleOpenAssociateProduct(props.original)}
                    >
                      Vincular Produto
                    </button>
                  ),
              },
              {
                Header: 'Cód. Fornecedor',
                accessor: 'providerCode',
                headerClassName: 'text-center',
                width: 110,
                className: 'texto',
              },
              {
                Header: 'Descrição',
                accessor: 'description',
                headerClassName: 'text-center',
                width: 170,
                className: 'texto',
              },
              {
                Header: () => (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    Bs. Multi
                    <span style={{ color: 'red' }}>*</span>
                  </div>
                ),
                accessor: 'multiplierBase',
                width: 80,
                Cell: (props) => (
                  <input
                    id={`baseMulti${props.index}`}
                    className="form-control new-foco-input"
                    type="number"
                    value={props.original.multiplierBase}
                    style={{ fontSize: '12px' }}
                    onChange={(e) =>
                      handleMultiplierBaseChange(
                        e,
                        props.index,
                        props.original.productId
                      )
                    }
                  />
                ),
              },
              {
                Header: 'Qtd.',
                width: 80,
                accessor: 'quantity',
                headerClassName: 'text-center',
                Cell: ({ value }) => formatQuantityWithThousands(value),
              },
              {
                Header: 'Marca',
                width: 120,
                Cell: (props) => (
                  <InputContainer variant="column">
                    <InputSelectInTable
                      width={'100%'}
                      height={'35px'}
                      options={brands}
                      value={props.original.brand}
                      fontSize={'12px'}
                      onChange={(e) => handleChangeBrand(e, props.index)}
                      isSearchable={true}
                      isRowTable={false}
                    />
                  </InputContainer>
                ),
              },
              {
                Header: 'R$ Compra',
                accessor: 'unitValue',
                width: 80,
                Cell: ({ value }) => currency(value),
              },
              {
                Header: 'Margem',
                accessor: 'margin',
                width: 70,
                Cell: (props) => {
                  if (!props.original.productId) {
                    return '';
                  } else {
                    return formatThousandWithPercentage(props.value);
                  }
                },
              },
              {
                Header: 'R$ Venda',
                accessor: 'salesPrice',
                width: 70,
                Cell: (props) => {
                  if (!props.original.productId) {
                    return '';
                  } else {
                    return currency(props.value);
                  }
                },
              },
              {
                Header: () => (
                  <div
                    style={{
                      alignItems: 'center',
                      color: '#3E8AF7',
                    }}
                  >
                    Nv. Margem
                    <br />
                    (Varejo)
                    <span style={{ color: 'red' }}>*</span>
                  </div>
                ),
                accessor: 'newMargin',
                width: 90,
                Cell: (props) => {
                  return (
                    <PercentInput
                      className="form-control new-foco-input"
                      value={props.original.newMargin}
                      id={`items[${props.index}].newMargin`}
                      name={`items[${props.index}].newMargin`}
                      style={{ fontSize: '12px', width: '80px' }}
                      onChangeEvent={(e) => {
                        dispatch(
                          change(
                            'PurchaseXmlImport',
                            `items[${props.index}].newMargin`,
                            e.target.value
                          )
                        );

                        handleChangeValueMargin(
                          props.index,
                          props.original,
                          e.target.value
                        );

                        setTimeout(() => {
                          document
                            .getElementById(`items[${props.index}].newMargin`)
                            .focus();
                        }, 50);
                      }}
                    />
                  );
                },
              },
              {
                Header: () => (
                  <div
                    style={{
                      alignItems: 'center',
                      color: '#3E8AF7',
                    }}
                  >
                    Nv. R$ Venda
                    <br />
                    (Varejo)
                    <span style={{ color: 'red' }}>*</span>
                  </div>
                ),
                accessor: 'newSalesPrice',
                width: 90,
                Cell: (props) => {
                  return (
                    <InputCurrency
                      id={`newSalesPrice${props.index}`}
                      width={80}
                      height={35}
                      value={props.original.newSalesPrice}
                      style={{ fontSize: '12px' }}
                      borderColor={'#E3E3E3'}
                      borderColorFocus={'#AAAAAA'}
                      onChange={(value) => {
                        dispatch(
                          change(
                            'PurchaseXmlImport',
                            `items[${props.index}].newSalesPrice`,
                            value
                          )
                        );
                        handleChangeValueSalesPrice(
                          props.index,
                          props.original,
                          value
                        );
                      }}
                    />
                  );
                },
              },
              ...(isWholesale
                ? [
                    {
                      Header: () => (
                        <div
                          style={{
                            alignItems: 'center',
                            color: '#3E8AF7',
                          }}
                        >
                          Nv. Margem
                          <br />
                          (Atacado)
                          <span style={{ color: 'red' }}>*</span>
                        </div>
                      ),
                      accessor: 'profitMarginWholesale',
                      width: 100,
                      Cell: (props) => {
                        return (
                          <PercentInput
                            className="form-control new-foco-input"
                            value={props.original.profitMarginWholesale}
                            id={`items[${props.index}].profitMarginWholesale`}
                            name={`items[${props.index}].profitMarginWholesale`}
                            style={{ fontSize: '12px', width: '80px' }}
                            onChangeEvent={(e) => {
                              dispatch(
                                change(
                                  'PurchaseXmlImport',
                                  `items[${props.index}].profitMarginWholesale`,
                                  e.target.value
                                )
                              );

                              handleChangeValueMarginWholesale(
                                props.index,
                                props.original,
                                e.target.value
                              );

                              setTimeout(() => {
                                document
                                  .getElementById(
                                    `items[${props.index}].profitMarginWholesale`
                                  )
                                  .focus();
                              }, 50);
                            }}
                          />
                        );
                      },
                    },
                    {
                      Header: () => (
                        <div
                          style={{
                            alignItems: 'center',
                            color: '#3E8AF7',
                          }}
                        >
                          Nv. R$ Venda
                          <br />
                          (Atacado)
                          <span style={{ color: 'red' }}>*</span>
                        </div>
                      ),
                      accessor: 'salePriceWholesale',
                      width: 100,
                      Cell: (props) => {
                        return (
                          <InputCurrency
                            id={`salePriceWholesale${props.index}`}
                            width={80}
                            height={35}
                            value={props.original.salePriceWholesale}
                            style={{ fontSize: '12px' }}
                            borderColor={'#E3E3E3'}
                            borderColorFocus={'#AAAAAA'}
                            onChange={(value) => {
                              dispatch(
                                change(
                                  'PurchaseXmlImport',
                                  `items[${props.index}].salePriceWholesale`,
                                  value
                                )
                              );
                              handleChangeValueSalesPriceWholesale(
                                props.index,
                                props.original,
                                value
                              );
                            }}
                          />
                        );
                      },
                    },
                  ]
                : []),

              {
                Header: (props) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {props.data && props.data[0]._original.CST === ''
                      ? 'CSOSN'
                      : 'CST'}
                  </div>
                ),
                accessor: 'CSTorCSOSN',
                width: 80,
              },
              {
                Header: 'CFOP',
                accessor: 'cfop',
                width: 80,
              },
              {
                Header: 'NCM',
                accessor: 'ncm',
                width: 80,
              },
              {
                Header: 'CEST',
                accessor: 'cest',
                width: 80,
              },
              {
                Header: 'Cód. de Barras (EAN)',
                accessor: 'cEAN',
                width: 130,
              },
              {
                Header: 'Cód. de Barras (EAN Tributável)',
                accessor: 'cEANTrib',
                width: 170,
              },
              {
                Header: 'Cód. ANP',
                accessor: 'CodANP',
                width: 100,
              },
            ]}
            defaultPageSize={items.filter(handleFilters).length}
            loading={false}
            ober
            showPagination={false}
            sortable={true}
            showPaginationTop={false}
            showPaginationBottom={false}
            defaultSorted={[
              {
                id: 'providerCode',
                desc: true,
              },
            ]}
            loadingText="Carregando..."
            noDataText="Nenhum Produto encontrado"
          />
        </div>
      </div>
      <div className="xml-import-products-buttons">
        <button
          type="button"
          className="xml-import-button-danger button-h35"
          onClick={() => previousPage()}
        >
          Voltar
        </button>
        {items.filter((p) => !p.productCode).length ? (
          <div className="xml-import-products-buttons-right">
            <button
              type="button"
              className="xml-import-button-info button-h35"
              onClick={handleCreateItems}
            >
              Cadastrar Produtos Sem Código
            </button>
            <button
              type="button"
              className="xml-import-button-success button-h35"
              onClick={
                company.companyConfig.advancedPricing
                  ? handleNextStep
                  : onFinish
              }
              disabled={loading}
            >
              {company.companyConfig.advancedPricing ? 'Avançar' : 'Concluir'}
            </button>
          </div>
        ) : (
          <button
            type="button"
            className="xml-import-button-success"
            onClick={
              company.companyConfig.advancedPricing ? handleNextStep : onFinish
            }
            disabled={loading}
          >
            {company.companyConfig.advancedPricing ? 'Avançar' : 'Concluir'}
          </button>
        )}
      </div>

      {modalAssociateProduct && (
        <ModalAssociateProduct
          onHide={handleCloseAssociateProduct}
          onCancel={handleCloseAssociateProduct}
          onSubmit={handleCloseAssociateProduct}
          itemToEdit={itemToEdit}
        />
      )}
    </>
  );
}

export default Products;
